<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>上课点名</span>
    </div>
    <el-row>
      <el-row>
        <el-col :span="10">
          <div><span>班级：{{className}}</span></div>
        </el-col>
        <el-col :span="10">
          <div><span>上课时间：{{schooltime}}</span></div>
        </el-col>
      </el-row>
      <el-table
        :data="dataList"
        border
        fit
        highlight-current-row
        class="tb-list"
      >
        <el-table-column label="学号" align="center" prop="loginName"></el-table-column>
        <el-table-column label="姓名" align="center" prop="name"></el-table-column>
        <el-table-column label="性别" align="center" prop="sex"></el-table-column>
        <el-table-column label="来源" align="center" prop="source"></el-table-column>
        <el-table-column label="是否出勤" align="center" width="180">
          <template slot-scope="scope">
            <el-checkbox
              v-model="scope.row.isAttend"
              true-label="是"
              false-label="否"
            >是否出勤</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column v-if="productId" label="是否课消" align="center" width="180">
          <template slot-scope="scope">
            <el-row v-if="scope.row.coseStatus">已课消({{(scope.row.cost / 60).toFixed(2)}}课时)</el-row>
            <el-row v-else>未课消</el-row>
            <el-checkbox
              v-has="'class:showcheckboxAttendance'"
              v-model="scope.row.coseStatus"
              @change="handleChangeCheckbox(scope.row)"
            >是否课消</el-checkbox>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row slot="footer">
      <el-button type="primary" @click="confirm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getLessionStudentMessage, signLesson,setCost } from '@/api/senate/class'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    productId: {
      default: '',
      type: String,
      required: true
    },
    lesson_id: {
      default: '',
      type: String,
      required: true
    },
    schooltime: {
      default: '',
      type: String
    },
    className: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      dataList: []
    }
  },
  methods: {
    handleChangeCheckbox(row) {
      this.dataList.splice()
      this.setCost(row,row.student_id,row.coseStatus)
    },
    openDialog () {
      this.getLessionStudentMessage()
    },
    async setCost(row,student_id,isCost) {
      const response = await setCost({
        lessonId:this.lesson_id,
        studentId:student_id,
        isCost:isCost
      })
      if(response.state === "success") {
        row.cost = response.body.cost
      }
    },
    async getLessionStudentMessage () {
      const res = await getLessionStudentMessage({
        lesson_id: this.lesson_id
      })
      this.dataList = res.body
      this.dataList.forEach((item)=> {
        if(item.cost === 0) {
          item.coseStatus =  false
        }else {
          item.coseStatus =  true
        }
      })
    },

    close () {
      this.$emit('close')
    },

    confirm () {
      const dataList = []
      this.dataList.forEach((ele, idx) => {
        if (ele.isAttend === '是') {
          dataList.push({
            isAttend: true,
            userId: ele.student_id
          })
        } else {
          dataList.push({
            isAttend: false,
            userId: ele.student_id
          })
        }
      })
      signLesson({
        lessonId: this.lesson_id,
        attendInfo: dataList
      }).then(res => {
        console.log('res', res)
        if (res.state === 'success') {
          window.$msg('点名成功')
          this.close()
          this.$emit('updateView')
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../../style/dialog.scss"
</style>
