var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("上课点名")])]
      ),
      _c(
        "el-row",
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 10 } }, [
                _c("div", [
                  _c("span", [_vm._v("班级：" + _vm._s(_vm.className))]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 10 } }, [
                _c("div", [
                  _c("span", [_vm._v("上课时间：" + _vm._s(_vm.schooltime))]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticClass: "tb-list",
              attrs: {
                data: _vm.dataList,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "学号", align: "center", prop: "loginName" },
              }),
              _c("el-table-column", {
                attrs: { label: "姓名", align: "center", prop: "name" },
              }),
              _c("el-table-column", {
                attrs: { label: "性别", align: "center", prop: "sex" },
              }),
              _c("el-table-column", {
                attrs: { label: "来源", align: "center", prop: "source" },
              }),
              _c("el-table-column", {
                attrs: { label: "是否出勤", align: "center", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { "true-label": "是", "false-label": "否" },
                            model: {
                              value: scope.row.isAttend,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "isAttend", $$v)
                              },
                              expression: "scope.row.isAttend",
                            },
                          },
                          [_vm._v("是否出勤")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.productId
                ? _c("el-table-column", {
                    attrs: { label: "是否课消", align: "center", width: "180" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.coseStatus
                                ? _c("el-row", [
                                    _vm._v(
                                      "已课消(" +
                                        _vm._s(
                                          (scope.row.cost / 60).toFixed(2)
                                        ) +
                                        "课时)"
                                    ),
                                  ])
                                : _c("el-row", [_vm._v("未课消")]),
                              _c(
                                "el-checkbox",
                                {
                                  directives: [
                                    {
                                      name: "has",
                                      rawName: "v-has",
                                      value: "class:showcheckboxAttendance",
                                      expression:
                                        "'class:showcheckboxAttendance'",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleChangeCheckbox(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.coseStatus,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "coseStatus", $$v)
                                    },
                                    expression: "scope.row.coseStatus",
                                  },
                                },
                                [_vm._v("是否课消")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2505636085
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: { click: _vm.confirm },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }