var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", {
            domProps: { textContent: _vm._s(_vm.LessonDialogTitle) },
          }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "开始时间：", prop: "start_time" } },
            [
              _c("el-date-picker", {
                staticStyle: { float: "left" },
                attrs: {
                  type: "datetime",
                  format: "yyyy-MM-dd HH:mm",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  placeholder: "选择开始上课时间",
                },
                on: { change: _vm.startTimeChange },
                model: {
                  value: _vm.formData.start_time,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "start_time", $$v)
                  },
                  expression: "formData.start_time",
                },
              }),
              _vm.formData.start_time
                ? _c(
                    "el-row",
                    { staticStyle: { float: "left" } },
                    [
                      _vm.commonTimeOptions.length !== 0
                        ? _c(
                            "el-dropdown",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { trigger: "click" },
                              on: { command: _vm.commonTimeChange },
                              model: {
                                value: _vm.commonTime,
                                callback: function ($$v) {
                                  _vm.commonTime = $$v
                                },
                                expression: "commonTime",
                              },
                            },
                            [
                              _c("span", { staticClass: "el-dropdown-link" }, [
                                _vm._v(" 常用时间段"),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right",
                                }),
                              ]),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _vm._l(
                                    _vm.commonTimeOptions,
                                    function (item) {
                                      return _c(
                                        "el-dropdown-item",
                                        { attrs: { command: item } },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { float: "left" } },
                                            [
                                              _vm._v(
                                                _vm._s(item.startTime) + "~"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticStyle: { float: "left" } },
                                            [_vm._v(_vm._s(item.endTime))]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "el-dropdown-link",
                                              staticStyle: {
                                                float: "right",
                                                "margin-left": "15px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.deleteCommonTime(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "10px" },
                                      attrs: { size: "mini", type: "primary" },
                                      on: { click: _vm.gotoAddCommenTime },
                                    },
                                    [_vm._v("添加常用时间段")]
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { size: "mini", type: "primary" },
                              on: { click: _vm.gotoAddCommenTime },
                            },
                            [_vm._v("添加常用时间段")]
                          ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上课时长：", prop: "duration" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "20%" },
                attrs: { precision: 0, min: 0, controls: false },
                on: { change: _vm.timeDurationhandleChange },
                model: {
                  value: _vm.formData.duration,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "duration", $$v)
                  },
                  expression: "formData.duration",
                },
              }),
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-left": "10px",
                    "margin-right": "30px",
                  },
                },
                [_vm._v("分钟")]
              ),
              _c(
                "el-dropdown",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { trigger: "click" },
                  on: { command: _vm.timeDurationSelectChange },
                  model: {
                    value: _vm.timeDurationSelect,
                    callback: function ($$v) {
                      _vm.timeDurationSelect = $$v
                    },
                    expression: "timeDurationSelect",
                  },
                },
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _vm._v(" 常用时长"),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    _vm._l(_vm.timeDurationOptions, function (item) {
                      return _c(
                        "el-dropdown-item",
                        { attrs: { command: item.value } },
                        [
                          _c("span", { staticStyle: { float: "left" } }, [
                            _vm._v(_vm._s(item.label)),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "结束时间：" } },
            [
              _c("el-input", {
                staticStyle: { width: "30%" },
                attrs: { min: 0, disabled: "" },
                model: {
                  value: _vm.formData.endTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "endTime", $$v)
                  },
                  expression: "formData.endTime",
                },
              }),
            ],
            1
          ),
          !Boolean(_vm.classInfo.classroomId) &&
          Boolean(
            _vm.addlessonData === null ||
              !Boolean(_vm.addlessonData.classroom_id)
          )
            ? _c(
                "el-form-item",
                { attrs: { label: "排课教室：" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 14 } }, [
                        _c(
                          "div",
                          { staticStyle: { "margin-right": "20px" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: {
                                  width: "100%",
                                  cursor: "pointer",
                                },
                                attrs: {
                                  multiple: "",
                                  placeholder: "请选择教室",
                                },
                                on: { "remove-tag": _vm.removeRoom },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.seleRoom.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.formData.roomIds,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "roomIds", $$v)
                                  },
                                  expression: "formData.roomIds",
                                },
                              },
                              _vm._l(_vm.roomIds, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.title, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "has",
                                    rawName: "v-has",
                                    value: "class:roomFreeTime",
                                    expression: "'class:roomFreeTime'",
                                  },
                                ],
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.checkRoomTime },
                              },
                              [_vm._v("查看教室空闲时间")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { label: "排课教室：" } },
                [
                  _c(
                    "el-row",
                    [
                      _vm._l(_vm.lessRoom, function (item) {
                        return _c("span", { key: item.id }, [
                          _c("span", { on: { click: _vm.seleRoom } }, [
                            _vm._v(_vm._s(item.title) + "、"),
                          ]),
                        ])
                      }),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "has",
                              rawName: "v-has",
                              value: "class:roomFreeTime",
                              expression: "'class:roomFreeTime'",
                            },
                          ],
                          staticStyle: { "margin-left": "20px" },
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.checkRoomTime },
                        },
                        [_vm._v("查看教室空闲时间")]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
          !_vm.classInfo.zjUserIds
            ? _c(
                "el-form-item",
                { attrs: { label: "上课老师：", prop: "teacherIds" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 14 } }, [
                        _c(
                          "div",
                          { staticStyle: { "margin-right": "20px" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  multiple: "",
                                  placeholder: "请选择上课老师",
                                },
                                on: { "remove-tag": _vm.removeTeacher },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.seleTeacher.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.formData.teacherIds,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "teacherIds", $$v)
                                  },
                                  expression: "formData.teacherIds",
                                },
                              },
                              _vm._l(_vm.teacherIds, function (item) {
                                return _c("el-option", {
                                  key: item.userId,
                                  attrs: {
                                    label: item.name,
                                    value: item.userId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "has",
                                    rawName: "v-has",
                                    value: "class:roomFreeTime",
                                    expression: "'class:roomFreeTime'",
                                  },
                                ],
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.checkTime()
                                  },
                                },
                              },
                              [_vm._v("查看老师空闲时间")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { label: "上课老师：" } },
                [
                  _c(
                    "el-row",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.seleTeacher.apply(null, arguments)
                        },
                      },
                    },
                    _vm._l(_vm.seleTeaData, function (item) {
                      return _c("span", { key: item.userId }, [
                        _vm._v(_vm._s(item.name) + "、"),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
          _c(
            "el-form-item",
            { attrs: { label: "上课助教：" } },
            [
              !_vm.classInfo.assistantUserIds
                ? [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { multiple: "", placeholder: "请选择助教" },
                        on: { "remove-tag": _vm.removeAssistant },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.seleAssistant.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.formData.assistantIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "assistantIds", $$v)
                          },
                          expression: "formData.assistantIds",
                        },
                      },
                      _vm._l(_vm.assistantIds, function (item) {
                        return _c("el-option", {
                          key: item.userId,
                          attrs: { label: item.name, value: item.userId },
                        })
                      }),
                      1
                    ),
                  ]
                : [
                    _c(
                      "el-row",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.seleAssistant.apply(null, arguments)
                          },
                        },
                      },
                      _vm._l(_vm.seleAssisData, function (item) {
                        return _c("span", { key: item.userId }, [
                          _vm._v(_vm._s(item.name) + "、"),
                        ])
                      }),
                      0
                    ),
                  ],
            ],
            2
          ),
          !_vm.$route.query.productId || _vm.addlessonData != null
            ? _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "lesson:addThembLessonMember",
                      expression: "'lesson:addThembLessonMember'",
                    },
                  ],
                  attrs: { label: "上课学员：" },
                },
                [
                  _vm.addlessonData === null ||
                  _vm.addlessonData.studentNum === 0
                    ? [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { multiple: "", placeholder: "请选择学员" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.seleStudentIds.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.formData.studentIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "studentIds", $$v)
                              },
                              expression: "formData.studentIds",
                            },
                          },
                          _vm._l(_vm.studentIds, function (item) {
                            return _c("el-option", {
                              key: item.userId,
                              attrs: { label: item.name, value: item.userId },
                            })
                          }),
                          1
                        ),
                      ]
                    : [
                        _c(
                          "el-row",
                          {
                            nativeOn: {
                              click: function ($event) {
                                return _vm.seleStudentIds.apply(null, arguments)
                              },
                            },
                          },
                          _vm._l(_vm.stuList, function (item) {
                            return _c("span", { key: item.student_id }, [
                              _vm._v(
                                _vm._s(item.name) +
                                  "(" +
                                  _vm._s(item.loginName) +
                                  ")、"
                              ),
                            ])
                          }),
                          0
                        ),
                      ],
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: { click: _vm.saveRoleForm },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
      _c("sele-teacher", {
        attrs: {
          show: _vm.teacherDialog,
          campusId: _vm.campusId,
          classId: _vm.classInfo.classId,
          seleTeaData: _vm.seleTeaData,
        },
        on: {
          close: function ($event) {
            _vm.teacherDialog = false
          },
          teacherData: _vm.teacherData,
        },
      }),
      _c("sele-assistant", {
        attrs: {
          show: _vm.assistantDialog,
          seleAssisData: _vm.seleAssisData,
          classInfo: _vm.classInfo,
        },
        on: {
          close: function ($event) {
            _vm.assistantDialog = false
          },
          assistantData: _vm.assistantData,
        },
      }),
      _c("sele-room", {
        attrs: {
          show: _vm.roomDialog,
          stuList: _vm.stuList,
          campusId: _vm.classInfo.campus_id,
          lessRoom: _vm.lessRoom,
        },
        on: {
          close: function ($event) {
            _vm.roomDialog = false
          },
          seleRoomData: _vm.seleRoomData,
        },
      }),
      _c("check-time", {
        attrs: { show: _vm.timeDialog },
        on: {
          close: function ($event) {
            _vm.timeDialog = false
          },
        },
      }),
      _c("detection-clash", {
        attrs: { show: _vm.clashDialog, clashData: _vm.clashData },
        on: {
          close: function ($event) {
            _vm.clashDialog = false
            _vm.clashData = []
          },
        },
      }),
      _c("lesson-student", {
        attrs: {
          show: _vm.lessonStudentDialog,
          seleStuData: _vm.seleStuData,
          classInfo: _vm.classInfo,
        },
        on: {
          close: function ($event) {
            _vm.lessonStudentDialog = false
          },
          editStuData: _vm.editStuData,
        },
      }),
      _c("room-time", {
        attrs: { show: _vm.roomTimeDialog },
        on: {
          close: function ($event) {
            _vm.roomTimeDialog = false
          },
        },
      }),
      _c("add-common-time", {
        attrs: { show: _vm.showCommomTime },
        on: { close: _vm.closeCommonTimeDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }