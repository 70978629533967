import { getMyCampus } from '@/api/senate/class'

export default {
  data() {
    return {
      schoolArray: [], // 校区列表
    }
  },
  mounted() {
    this.getMyCampus()
  },
  methods: {
    // 获取全部校区
    async getMyCampus() {
      const res = await getMyCampus()
      this.schoolArray = res.body
    },
  },
}
