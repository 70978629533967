import $axios from '@/utils/request'

// 活动列表
export function getListActivity (data) {
  const url = '/activity/list'
  return $axios.fPost(url, data)
}

// 创建活动
export function activityAdd (data) {
  const url = '/activity/add'
  return $axios.fPost(url, data)
}

// 编辑活动
export function activityEdit (data) {
  const url = '/activity/edit'
  return $axios.fPost(url, data)
}

// 删除活动
export function activityDel (data) {
  const url = '/activity/del'
  return $axios.fPost(url, data)
}

// 查看活动详情
export function activityInfo (data) {
  const url = '/activity/info'
  return $axios.fPost(url, data)
}

// 获取活动成员列表
export function activityListMemberBrush (data) {
  const url = '/activity/listUserActivitySignRecord'
  return $axios.fPost(url, data)
}

// 获取活动成员列表
export function activityListMember (data) {
  const url = '/activity/listMember'
  return $axios.fPost(url, data)
}

// 添加活动成员
export function activityAddMember (data) {
  const url = '/activity/addMember'
  return $axios.fPost(url, data)
}


// 采集信息列表
export function listInfoBySourceId (data) {
  const url = '/openplatform/user/market/listInfoBySourceId'
  return $axios.fPost(url, data)
}

// 删除活动成员
export function activityDelMember (data) {
  const url = '/activity/delMember'
  return $axios.fPost(url, data)
}

// 获取活动记录
export function activityListSubmit (data) {
  const url = '/activity/listSubmit'
  return $axios.fPost(url, data)
}

// 下载活动作品
export function activityDownloadFile (data) {
  const url = '/activity/downloadFile'
  return $axios.fPost(url, data)
}

// 活动分组列表
export function getActivityGroup (data) {
  const url = '/activity/activityCourseContest/listInfoByActivity'
  return $axios.fGet(url, data)
}

// 活动分组列表
export function getActivityByGroup (data) {
  const url = '/activity/activityCourseContest/listInfo'
  return $axios.fGet(url, data)
}

// 活动分组新增
export function insertActiveGroup (data) {
  const url = '/activity/activityCourseContest/insert'
  return $axios.fPost(url, data)
}

// 活动分组编辑
export function editActiveGroup (data) {
  const url = '/activity/activityCourseContest/edit'
  return $axios.fPost(url, data)
}

// 活动分组删除
export function deleteActiveGroup (data) {
  const url = '/activity/activityCourseContest/delete'
  return $axios.fPost(url, data)
}

// 刷题打卡记录列表
export function getBushpunchList (data) {
  const url = '/activity/bushpunch/listInfo'
  return $axios.fPost(url, data)
}

// 刷题打卡记录编辑
export function editBushpunch (data) {
  const url = '/activity/bushpunch/edit'
  return $axios.fPost(url, data)
}

// 刷题打卡活动记录
export function getLogStatic (data) {
  const url = '/activity/bushpunch/logStatic'
  return $axios.fPost(url, data)
}
