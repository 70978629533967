<template>
  <el-drawer
    title="排课详情"
    :visible.sync="show"
    direction="rtl"
    size="50%"
    @open="openDrawer"
    :before-close="close">
    <el-row style="padding-left: 20px" class="container">
      <h4>排课信息：</h4>
      <p>上课时间：<span>{{ lessonData.start_time | formatDateStart }}</span>
      <span>{{ lessonData.end_time | formatDateEnd }}</span></p>
      <!-- lessonData：{{lessonData}} -->
      <template v-if="type === 'class'">
        <p>
          <span>讲师：<span v-text="lessonData.zjName && lessonData.zjName"></span></span>
          <span class="inline-block ml-10">助教：<span v-text="lessonData.assistant && lessonData.assistant"></span></span>
        </p>
      </template>
      <template v-else>
        <p>
          <span>讲师：<span v-text="lessonData.lessonTeacherList && getLessonTeacher(lessonData.lessonTeacherList, '主教')"></span></span>
          <span class="inline-block ml-10">助教：<span v-text="lessonData.lessonTeacherList && getLessonTeacher(lessonData.lessonTeacherList, '助教')"></span></span>
        </p>
      </template>
      <p><span>上课内容：<span v-if="Boolean(lessonData.content)">{{lessonData.content}}</span><span v-else>暂无</span></span></p>
      <p><span>学员出勤：</span><span>{{isAttendNum}} / {{total}}</span></p>
      <el-row>
        <el-table
        border
          fit
          highlight-current-row
          :data="LessionStudentList"
          style="margin-top: 0px;margin-bottom: 10px; width: 60%;"
        >
          <el-table-column label="学员" align="center" prop="name"></el-table-column>
          <el-table-column label="是否出勤" align="center" prop="isAttend"></el-table-column>
          <el-table-column label="课消" align="center" prop="cost"></el-table-column>
        </el-table>
      </el-row>
      <h4>课堂反馈：</h4>

      <template v-if="feedback">
        <el-row class="feedback_container">
          <p v-html="feedback"></p>
        </el-row>
      </template>
      <template v-else>
        <el-row class="feedback_container">暂无提交</el-row>
      </template>
    </el-row>
  </el-drawer>
</template>

<script>
import { getFeedback } from '@/api/senate/lesson'
import { getLessionStudentMessage } from '@/api/senate/class'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    lessonData: {
      default: () => {
        return {}
      },
      type: Object
    },
    type: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      feedback: null,
      LessionStudentList: [],
      isAttendNum: 0,
      total: 0,
    }
  },
  methods: {
    openDrawer() {
      this.feedback = null
      this.LessionStudentList = []
      this.isAttendNum = 0
      this.total = 0
      this.getFeedback()
      this.getLessionStudentMessage()
    },

    async getFeedback() {
      const res = await getFeedback({
        lessonId: this.lessonData.id
      })
      this.feedback = res.body.feedback
    },

    async getLessionStudentMessage(){
      const res = await getLessionStudentMessage({
        lesson_id: this.lessonData.id
      })
      this.LessionStudentList = res.body
      this.total = res.body.length
      this.LessionStudentList.forEach(item =>{
        if (item.isAttend === '是') {
          this.isAttendNum++
        }
      })
    },

    close() {
      this.$emit('close')
    },
    getLessonTeacher(data, type) {
      const dataList = data.filter(item => item.type === type)
      let msg = ''
      data.forEach(item => {
        if (item.type === type) {
          msg += item.teacher.name + '  '
        }
      });
      return msg
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-drawer__body {
  overflow: auto;
}

.container p {
  color: rgba(0, 0, 0, 0.647058823529412);
  margin-bottom: 15px;
}

.container h4 {
  margin-bottom: 10px;
}

.feedback_container {
  color: rgba(0, 0, 0, 0.647058823529412);
  font-size: 15px;
  // padding-left: 35px;
}
</style>
