<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="LessonDialogTitle"></span>
    </div>
    <!-- classInfo：{{classInfo}} -->
    <!-- <hr /> -->
    <!-- addlessonData:{{addlessonData}} -->
    <!-- addlessonData：{{addlessonData}}
    <hr>
    classInfo：{{classInfo}}
    <hr>
    lessRoom：{{lessRoom}}
    <hr>
    formData：{{formData}} -->
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="开始时间：" prop="start_time">
        <el-date-picker
          style="float: left"
          v-model="formData.start_time"
          type="datetime"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="startTimeChange"
          placeholder="选择开始上课时间">
        </el-date-picker>
        <el-row style="float: left" v-if="formData.start_time">
          <el-dropdown trigger="click" style="margin-left: 10px;"   v-if="commonTimeOptions.length !== 0" v-model="commonTime"  @command="commonTimeChange">
            <span class="el-dropdown-link">
              常用时间段<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="item"   v-for="item in commonTimeOptions">
                <span style="float: left">{{ item.startTime }}~</span>
                <span style="float: left">{{ item.endTime }}</span>
                <span class="el-dropdown-link" style="float: right;margin-left: 15px" @click.stop="deleteCommonTime(item)">删除</span>
              </el-dropdown-item>
              <el-button style="margin: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button v-else style="margin-left: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>
<!--        <el-select style="margin-left: 10px;" v-if="commonTimeOptions.length !== 0" v-model="commonTime"  @change="commonTimeChange" placeholder="常用时间段">-->
<!--          <el-option-->
<!--            v-for="item in commonTimeOptions"-->
<!--            :key="item.label"-->
<!--            :label="item.label"-->
<!--            :value="item.label"-->
<!--          >-->
<!--            <span style="float: left">{{ item.startTime }}~</span>-->
<!--            <span style="float: left">{{ item.endTime }}</span>-->
<!--            <span class="el-dropdown-link" style="float: right" @click.stop="deleteCommonTime(item)">删除</span>-->
<!--          </el-option>-->
<!--          <el-button style="margin: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>-->
<!--        </el-select>-->
<!--        <el-button v-else style="margin-left: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>-->
        </el-row>
      </el-form-item>

      <el-form-item label="上课时长：" prop="duration">
        <el-input-number style="width: 20%;" :precision="0" v-model="formData.duration" :min="0" :controls="false" @change="timeDurationhandleChange"></el-input-number>
        <span style="margin-left: 10px;margin-right: 30px;">分钟</span>
        <el-dropdown trigger="click" style="margin-left: 10px;"   v-model="timeDurationSelect"  @command="timeDurationSelectChange">
            <span class="el-dropdown-link">
              常用时长<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="item.value"   v-for="item in timeDurationOptions">
              <span style="float: left">{{ item.label}}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
<!--        <span style="margin-right: 10px;">常用时长</span>-->
<!--        <el-select v-model="timeDurationSelect" placeholder="快捷选择时长" @change="timeDurationSelectChange" clearable>-->
<!--          <el-option-->
<!--            v-for="item in timeDurationOptions"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value">-->
<!--          </el-option>-->
<!--        </el-select>-->
      </el-form-item>
      <el-form-item label="结束时间：">
        <el-input style="width: 30%;" v-model="formData.endTime" :min="0" disabled></el-input>
      </el-form-item>
      <el-form-item label="排课教室：" v-if="!Boolean(classInfo.classroomId) && Boolean(addlessonData === null || !Boolean(addlessonData.classroom_id))">
        <el-row>
          <el-col :span="14">
            <div style="margin-right: 20px">
              <el-select
                v-model="formData.roomIds"
                multiple
                placeholder="请选择教室"
                @click.native="seleRoom"
                style="width: 100%;cursor: pointer"
                @remove-tag="removeRoom"
              >
                <el-option
                  v-for="item in roomIds"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div><el-button type="primary" size="small" @click="checkRoomTime" v-has="'class:roomFreeTime'">查看教室空闲时间</el-button></div>
          </el-col>
        </el-row>
      </el-form-item>
      <!-- 有默认教室 -->
      <el-form-item v-else label="排课教室：">
        <el-row>
          <!-- lessRoom：{{lessRoom}} -->
          <span v-for="item of lessRoom" :key="item.id">
            <span @click="seleRoom">{{item.title}}、</span>
          </span>
          <el-button type="primary" size="small" @click="checkRoomTime" v-has="'class:roomFreeTime'" style="margin-left: 20px;">查看教室空闲时间</el-button>
        </el-row>
      </el-form-item>
      <el-form-item label="上课老师：" v-if="!classInfo.zjUserIds" prop="teacherIds">
        <el-row>
          <el-col :span="14">
            <div style="margin-right: 20px;">
              <el-select
                v-model="formData.teacherIds"
                multiple
                placeholder="请选择上课老师"
                @click.native="seleTeacher"
                style="width: 100%"
                @remove-tag="removeTeacher"
              >
                <el-option
                  v-for="item in teacherIds"
                  :key="item.userId"
                  :label="item.name"
                  :value="item.userId"
                ></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div>
              <el-button type="primary" size="small" @click="checkTime()" v-has="'class:roomFreeTime'">查看老师空闲时间</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="上课老师：" v-else>
        <!-- seleTeaData：{{seleTeaData}} -->
        <el-row @click.native="seleTeacher">
          <span v-for="item of seleTeaData" :key="item.userId">{{ item.name }}、</span>
        </el-row>
      </el-form-item>
      <!-- 选择助教 -->
      <el-form-item label="上课助教：">
        <template
          v-if="!classInfo.assistantUserIds"
        >
          <el-select
            v-model="formData.assistantIds"
            multiple
            placeholder="请选择助教"
            @click.native="seleAssistant"
            style="width: 100%"
            @remove-tag="removeAssistant"
          >
            <el-option
              v-for="item in assistantIds"
              :key="item.userId"
              :label="item.name"
              :value="item.userId"
            ></el-option>
          </el-select>
        </template>
        <template v-else>
          <!-- seleAssisData：{{seleAssisData}} -->
          <el-row @click.native="seleAssistant">
            <span v-for="item of seleAssisData" :key="item.userId">{{ item.name }}、</span>
          </el-row>
        </template>
      </el-form-item>
      <!-- 选择上课学员 -->
      <el-form-item label="上课学员：" v-if="!$route.query.productId || addlessonData != null" v-has="'lesson:addThembLessonMember'">
        <template v-if="addlessonData === null || addlessonData.studentNum === 0">
          <el-select
            v-model="formData.studentIds"
            multiple
            placeholder="请选择学员"
            @click.native="seleStudentIds"
            style="width: 100%"
          >
            <el-option
              v-for="item in studentIds"
              :key="item.userId"
              :label="item.name"
              :value="item.userId"
            ></el-option>
          </el-select>
        </template>
        <template v-else>
          <el-row @click.native="seleStudentIds">
            <span
              v-for="item of stuList"
              :key="item.student_id"
            >{{ item.name }}({{ item.loginName }})、</span>
          </el-row>
        </template>
      </el-form-item>
<!--      <el-form-item label="关联课节：" prop="courseUnitId">-->
<!--        <template>-->
<!--          <el-select v-model="formData.courseUnitId" multiple  placeholder="请选择课节" @change="selectChange">-->
<!--            <el-option-->
<!--              v-for="item in options"-->
<!--              :key="item.id"-->
<!--              :label="item.title"-->
<!--              :value="item.id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </template>-->
<!--      </el-form-item>-->
    </el-form>
    <el-row slot="footer">
      <el-button type="primary" @click="saveRoleForm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>

    <!-- 选择老师 -->
    <sele-teacher :show="teacherDialog" :campusId="campusId" :classId="classInfo.classId" @close="teacherDialog=false" @teacherData="teacherData" :seleTeaData="seleTeaData"></sele-teacher>
    <!-- 选择助教 -->
    <sele-assistant :show="assistantDialog" @close="assistantDialog=false" @assistantData="assistantData" :seleAssisData="seleAssisData" :classInfo="classInfo"></sele-assistant>
    <!-- seleStudent -->
<!--    <sele-student-->
<!--      :show="userStudentDialog"-->
<!--      @close="userStudentDialog = false"-->
<!--      @editStuData="editStuData"-->
<!--      :seleStuData="seleStuData"-->
<!--    ></sele-student>-->

    <!-- 选择教室 dialog -->
    <sele-room
      :show="roomDialog"
      :stuList="stuList"
      :campusId="classInfo.campus_id"
      @close="roomDialog=false"
      @seleRoomData="seleRoomData"
      :lessRoom="lessRoom"
    ></sele-room>

    <!-- quesLibrary -->
    <!-- <question-library
      :show="contestDialog"
      @close="contestDialog = false"
      @seleContestData="seleContestData"
      :lessContest="lessContest"
    ></question-library> -->

    <!-- 查看老师空闲时间 -->
    <check-time :show="timeDialog" @close="timeDialog=false"></check-time>
    <!-- 冲突检测 -->
    <detection-clash :show="clashDialog" @close="clashDialog=false;clashData=[]" :clashData="clashData"></detection-clash>
    <!-- 选择学员 -->
    <lesson-student :show="lessonStudentDialog" @close="lessonStudentDialog=false" :seleStuData="seleStuData" @editStuData="editStuData" :classInfo="classInfo"></lesson-student>
    <!-- 查看教室空闲时间 -->
    <room-time :show="roomTimeDialog" @close="roomTimeDialog=false"
    ></room-time>
    <add-common-time :show="showCommomTime" @close="closeCommonTimeDialog" ></add-common-time>
  </el-dialog>
</template>

<script>
import seleTeacher from '../sele-newteacher'
import seleAssistant from '../sele-newassistantIds'
import seleStudent from '../sele-student'

// import questionLibrary from "@/components/public/questionlibrary";
import { replaceTeacher, replaceStudent } from '@/api/senate/lesson'
import { getCourseUnitList } from '@/api/research/curriculum'
import { getLessionStudentMessage } from '@/api/senate/class'
import CheckTime from '@/components/senate/check-time'
import detectionClash from '@/components/senate/detection-clash'
import seleRoom from '@/components/senate/sele-room'
import lessonStudent from '@/components/senate/lesson-student'
import roomTime from '@/components/senate/room-time'
import {listTeacherLevel,deleteUserPeriod} from '@/api/user'
import addCommonTime from '@/components/senate/add-commontime'
import { formatTate } from '@/utils/formatDate'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    },
    campusId: {
      default: "",
      type: String
    },
    addlessonData: {
      default: () => {
        return {}
      },
      type: Object
    },
    isMyLesson: {
      default: false,
      type: Boolean
    }
  },
  components: {
    seleTeacher,
    seleAssistant,
    seleStudent,
    CheckTime,
    detectionClash,
    seleRoom,
    roomTime,
    addCommonTime,
    lessonStudent
    // editStudent,
    // questionLibrary,
  },
  data () {
    return {
      LessonDialogTitle: '',
      roomIds: [],
      lessRoom: [],
      formData: {
        teacherIds: [],
        assistantIds: [],
        studentIds: [],
        contestIds: [],
        hour: '00',
        minute: '00'
      },
      options: [],
      teacherDialog: false,
      assistantDialog: false,
      // userStudentDialog: false,
      lessonStudentDialog:false,
      roomDialog: false,
      contestDialog: false,
      timeDialog: false,
      roomTimeDialog: false,
      rules: {
        start_time: [
          { required: true, message: '请选择上课时间段', trigger: 'blur' }
        ],
        courseUnitId: [
          { required: true, message: '请选择关联课节', trigger: 'blur' }
        ],
        duration: [
          { required: true, message: '上课时长不能为空', trigger: 'blur' }
        ],
        teacherIds: [
          { required: true, message: '上课讲师不能为空', trigger: 'blur' }
        ]
      },
      schoolArray: [],
      teacherIds: [], // 已选老师
      assistantIds: [], // 已选助教
      studentIds: [], // 已选学生
      contestIds: [], // 已选题库
      stuList: [], // 排课学员,
      seleTeaData: [], // 排课老师
      seleAssisData: [], // 排课助教
      lessStu: [], // 排课学员
      lessContest: [],
      seleStuData: [],
      class_id: '',
      timeDialog: false, // 查看老师空闲时间 dialog
      clashDialog: false,
      clashData: [],
      lesson_id: '',
      timeDurationSelect:'',
      timeDurationOptions: [ // 时长
        {
          value: 30,
          label: '30分钟'
        },
        {
          value: 45,
          label: '45分钟'
        },
        {
          value: 60,
          label: '60分钟'
        },
        {
          value: 90,
          label: '90分钟'
        },
        {
          value: 120,
          label: '120分钟'
        },
        {
          value: 180,
          label: '180分钟'
        }
      ],
      commonTimeOptions:[],
      showCommomTime:false,
      commonTime:''
    }
  },
  methods: {
    commonTimeChange(command) {
      if(this.formData.start_time !== undefined){
        console.log(this.commonTime.split('~')[0])
        this.formData.start_time = this.formData.start_time.substring(0,10)+' '+command.startTime+':00'
        this.formData.endTime = this.formData.start_time.substring(0,10)+' '+command.endTime+':00'
        this.formData.duration = ((new Date(this.formData.endTime)).getTime() - (new Date(this.formData.start_time)).getTime()) / 60 / 1000

        let flag = false
        this.timeDurationOptions.forEach((item) => {
          if (item.value === parseInt(this.formData.duration)) {
            this.timeDurationSelect = this.formData.duration
            flag = true
          }
        })
        if(!flag) {
          this.timeDurationSelect = ''
        }
      }
    },
    async deleteUserPeriod(row) {
      const response = await deleteUserPeriod({
        usperiodId:row.id
      })
      if(response && response.state === 'success') {
        window.$msg('删除成功')
        this.listTeacherLevel()
        this.commonTime = ''
        this.$forceUpdate()
      }
    },
    async listTeacherLevel() {
      const response = await listTeacherLevel()
      if(response && response.state === 'success') {
        this.commonTimeOptions = response.body
      }
      this.commonTimeOptions.forEach((item)=>{
        item.label = item.startTime+'~'+item.endTime
      })
    },
    closeCommonTimeDialog() {
      this.showCommomTime = false
      this.listTeacherLevel()
      this.$forceUpdate()
    },
    deleteCommonTime(row) {
      this.$confirm('确定删除此常用时间段吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteUserPeriod(row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    gotoAddCommenTime() {
      this.showCommomTime = true
    },
    startTimeChange() {
      this.commonTime = ''
      let oldTime = 0
      if(this.formData.duration !== undefined) {
        oldTime = (new Date(this.formData.start_time)).getTime() + parseInt(this.formData.duration) * 60 * 1000;
      }else {
        oldTime = (new Date(this.formData.start_time)).getTime()
      }
      if(this.formData.start_time !== null) {
        this.formData.endTime = formatTate(oldTime);
      }else {
        this.formData.endTime = undefined
      }
      this.$forceUpdate()
    },
    timeDurationhandleChange() {
      this.commonTime = ''
      let flag = false
      this.timeDurationOptions.forEach((item) => {
        if (item.value === parseInt(this.formData.duration)) {
          this.timeDurationSelect = this.formData.duration
          flag = true
        }
      })
      if(!flag) {
        this.timeDurationSelect = ''
      }
      if(this.formData.start_time !== undefined) {
        var oldTime = (new Date(this.formData.start_time)).getTime() + parseInt(this.formData.duration) * 60 * 1000;
        this.formData.endTime = formatTate(oldTime);
        this.$forceUpdate()
      }
    },
    timeDurationSelectChange(command) {
      this.commonTime = ''
      this.timeDurationSelect = command
      this.formData.duration = this.timeDurationSelect

      if(this.formData.start_time !== undefined && this.formData.start_time !== null) {
        if( this.formData.duration !== '') {
          var oldTime = (new Date(this.formData.start_time)).getTime() + parseInt(this.formData.duration) * 60 * 1000;
          this.formData.endTime = formatTate(oldTime);
          this.$forceUpdate()
        }else {
          this.formData.endTime = this.formData.start_time
        }
      }else{
        this.formData.endTime = ''
      }
    },
    checkRoomTime () {
      this.roomTimeDialog = true
    },

    removeRoom (tag) {
      this.roomIds = []
    },

    removeTeacher (tag) {
      this.teacherIds = []
      this.seleTeaData = []
    },

    removeAssistant (tag) {
      this.assistantIds.forEach((ele, idx)=> {
        if (ele.id === tag) {
          this.assistantIds.splice(idx, 1)
        }
      })
    },

    forceUpdate () {
      this.$forceUpdate()
    },
    selectChange (id) {
      console.log(id)
      // this.formData.courseUnitId = id;
    },
    openDialog () {
      this.formData = {
        hour: '00',
        minute: '00'
      }
      this.options = []
      this.commonTime = ""
      this.seleAssisData = []
      this.lessContest = []
      this.seleTeaData = []
      this.lessStu = []
      this.lessRoom = []
      // console.log("this.addlessonData"+JSON.stringify(this.addlessonData));
      if(this.$route.query.classID === undefined) {
        this.class_id = this.classInfo.classId
      }else {
        this.class_id = this.$route.query.classID
      }

      if (this.classInfo.classroomId && !this.addlessonData) {
        this.lessRoom.push({
          id: this.classInfo.classroomId,
          title: this.classInfo.classroomTitle
        })
        this.roomIds = this.lessRoom
        let roomIdsArray = []
        this.roomIds.map(item => {
          roomIdsArray.push(item.id)
        })
        this.formData.roomIds = roomIdsArray
      } else if (this.addlessonData && this.addlessonData.classroom_id) {
        this.lessRoom.push({
          id: this.addlessonData.classroom_id,
          title: this.addlessonData.classroomTitle
        })
        this.roomIds = this.lessRoom
        let roomIdsArray = []
        this.roomIds.map(item => {
          roomIdsArray.push(item.id)
        })
        this.formData.roomIds = roomIdsArray
      } else {
        this.formData.roomIds = []
      }

      // 上课助教
      let AssIds = []
      let AsName = []
      if (this.classInfo.assistantUserIds && this.classInfo.assistant && !this.addlessonData) {
        AssIds = this.classInfo.assistantUserIds.split(',')
        AsName = this.classInfo.assistant.split(',')
      } else if (this.addlessonData && this.addlessonData.assistantUserIds) {
        AssIds = this.addlessonData.assistantUserIds.split(',')
        AsName = this.addlessonData.assistant.split(',')
      } else {}

      if (AssIds.length !== 0 && AsName.length !== 0) {
        this.seleAssisData = []
        AssIds.forEach(item => {
          this.seleAssisData.push({
            userId: item,
          })
        })
        AsName.forEach((item, index) => {
          this.seleAssisData.forEach((ele, idx) => {
            if (index === idx) {
              ele.name = item
            }
          })
        })

        let AssArray = []
        this.seleAssisData.map(item => {
          AssArray.push(item.userId)
        })
        this.formData.assistantIds = AssArray
      } else {
        this.formData.assistantIds = []
      }

      // 上课老师
      if (this.classInfo.zjUserIds && this.classInfo.zjName && !this.addlessonData) {
        this.seleTeaData = []
        this.seleTeaData.push({
          userId: this.classInfo.zjUserIds,
          name: this.classInfo.zjName,
        })
        let teacherArray = []
        this.seleTeaData.map(item => {
          teacherArray.push(item.userId)
        })
        this.formData.teacherIds = teacherArray
      } else if (this.addlessonData && this.addlessonData.zjUserIds) {
        this.seleTeaData = []
        this.seleTeaData.push({
          userId: this.addlessonData.zjUserIds,
          name: this.addlessonData.zjName,
        })
        let teacherArray = []
        this.seleTeaData.map(item => {
          teacherArray.push(item.userId)
        })
        this.formData.teacherIds = teacherArray
      } else {
        this.formData.teacherIds = []
      }
      this.listTeacherLevel()
      // this.getCourseUnitList()
      // if(this.addlessonData.class_id) {
      if (!this.addlessonData) {
        this.LessonDialogTitle = '新增排课'
        this.seleStuData = []
        this.lesson_id = ''
        this.timeDurationSelect = ''
        // 班级管理页面
      } else {
        this.LessonDialogTitle = '编辑排课'
        this.formData = Object.assign({}, this.addlessonData)
        this.formData.start_time = this.addlessonData.start_time
        this.formData.duration = ((new Date(this.addlessonData.end_time)).getTime() - (new Date(this.addlessonData.start_time)).getTime()) / 60 / 1000

        let flag = false
        this.timeDurationOptions.forEach((item) => {
          if (item.value === parseInt(this.formData.duration)) {
            this.timeDurationSelect = this.formData.duration
            flag = true
          }
        })
        if(!flag) {
          this.timeDurationSelect = ''
        }
        this.formData.endTime = this.formData.end_time
        this.lesson_id = this.addlessonData.id
        this.getLessionStudentMessage()
      }
    },



    subtract_hour (a, b) {
      let result = Math.abs((Number(a) - Number(b)))
      if (result < 10) {
        result = '0' + result
      }
      return result
    },

    subtract_minute (a, b) {
      let result = Math.abs((Number(a) - Number(b)))
      if (result < 10) {
        result = '0' + result
      }
      return result
    },

    // 获取课节 列表
    async getCourseUnitList () {
      const res = await getCourseUnitList({
        pageNum: 1,
        pageSize: 1000,
        classId: this.class_id
      })
      for (let i = 0; i < res.body.list.length; i++) {
        var object = new Object();
        object.title = res.body.list[i].title;
        object.id = res.body.list[i].id;
        this.options.push(object);
      }

    },
    // 获取当前排课学员
    async getLessionStudentMessage () {
      const res = await getLessionStudentMessage({
        lesson_id: this.lesson_id
      })
      this.stuList = res.body
      if (this.stuList.length !== 0) {
        let stuData = [];
        this.stuList.map((item, index) => {
          stuData.push(
            Object.assign({}, item, {
              userId: item.student_id,
              name: item.name
            })
          );
        });
        this.seleStuData = stuData;
      }
    },

    // 选择老师
    seleTeacher () {
      this.teacherDialog = true
    },

    // 选择助教
    seleAssistant () {
      this.assistantDialog = true
    },

    // 选择教室
    seleRoom () {
      this.roomDialog = true
    },

    seleRoomData (data) {
      this.lessRoom = [ ...data ]
      this.roomIds = data
      let roomIdsArray = []
      this.roomIds.map(item => {
        roomIdsArray.push(item.id)
      })
      this.formData.roomIds = roomIdsArray
    },

    // 选择学生
    seleStudentIds () {
      this.lessonStudentDialog = true
    },

    // 选择题库
    seleContestIds () {
      this.contestDialog = true
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    // 新增|更新排课老师
    async teacherData (data) {
      if (this.lesson_id) {
        let teacherIdsArray = []
        data.map(item => {
          teacherIdsArray.push(item.userId)
        })
        replaceTeacher({
          lessonId: this.lesson_id,
          type: '主教',
          teacherIds: teacherIdsArray
        }).then(res => {
          if (res.state === 'success') {
            window.$msg("更新排课老师成功")
            if (!this.addlessonData.zjUserIds) {
              this.teacherIds = data
              this.seleTeaData = data
              let teacherIdsArray = []
              this.seleTeaData.map(item => {
                teacherIdsArray.push(item.userId)
              })
              this.formData.teacherIds = teacherIdsArray
            } else {
              this.seleTeaData = data
            }
          } else {
            if (res.errCode === '0201001') {
              this.clashFun(res)
            }
          }
        })
      } else {
        this.teacherIds = data
        this.seleTeaData = data
        let teacherIdsArray = []
        this.seleTeaData.map(item => {
          teacherIdsArray.push(item.userId)
        })
        this.formData.teacherIds = teacherIdsArray
      }
    },

    // 新增|更新排课助教
    async assistantData (data) {
      if (this.lesson_id) {
        let assistantIdsArray = []
        data.map(item => {
          assistantIdsArray.push(item.userId)
        })
        replaceTeacher({
          lessonId: this.lesson_id,
          type: '助教',
          teacherIds: assistantIdsArray
        }).then(res => {
          if (res.state === 'success') {
            window.$msg("更新排课助教成功")
            if (!this.addlessonData.assistantUserIds) {
              this.assistantIds = data
              this.seleAssisData = data
              let assistantIdsArray = []
              this.seleAssisData.map(item => {
                assistantIdsArray.push(item.userId)
              });
              this.formData.assistantIds = assistantIdsArray
            } else {
              this.seleAssisData = data
            }
          } else {
            if (res.errCode === '0201001') {
              this.clashFun(res)
              // this.clashData = res.body
              // this.clashDialog = true
            }
          }
        })
      } else {
        this.assistantIds = data
        this.seleAssisData = data
        let assistantIdsArray = []
        this.seleAssisData.map(item => {
          assistantIdsArray.push(item.userId)
        });
        this.formData.assistantIds = assistantIdsArray
      }
    },

    // 处理冲突
    clashFun (res) {
      if (res.body && res.body.length > 0) {
        var newArr = [];
        res.body.forEach((item, index) => {
          var flag = true
          if (newArr.length > 0) {
            for (var i= 0; i<newArr.length; i++) {
              if (newArr[i].id === item.id && newArr[i].conflictType !== item.conflictType) {
                console.log('相同id的冲突')
                newArr[i].conflictType = [newArr[i].conflictType, item.conflictType],
                newArr[i].classroomId = newArr[i].classroomId ? newArr[i].classroomId : item.classroomId,
                newArr[i].classroomTitle = newArr[i].classroomTitle ? newArr[i].classroomTitle : item.classroomTitle,
                newArr[i].teacherId = newArr[i].teacherId ? newArr[i].teacherId : item.teacherId,
                newArr[i].teacherName = newArr[i].teacherName ? newArr[i].teacherName : item.teacherName,
                newArr[i].teacherType = newArr[i].teacherType ? newArr[i].teacherType : item.teacherType,
                flag = false
                break
              }
            }
          }
          if (flag) {
            newArr.push(item)
          }
        })
      }
      this.clashData = newArr
      this.clashDialog = true
    },

    // 更新排课学员
    async editStuData (data) {
      if (this.lesson_id) {
        let studentIdsArray = []
        data.map(item => {
          studentIdsArray.push(item.userId)
        })
        await replaceStudent ({
          lessonId: this.lesson_id,
          studentIds: studentIdsArray
        })
        $msg("更新排课学员成功");
        this.lessonStudentDialog = false
        this.getLessionStudentMessage()
      } else {
        // console.log('新增操作')
        this.studentIds = data
        this.stuList = data
        let studentIdsArray = []
        this.studentIds.map(item => {
          studentIdsArray.push(item.userId)
        })
        this.formData.studentIds = studentIdsArray
        this.lessonStudentDialog = false;
      }
    },

    // 更新班级题库
    seleContestData (data) {
      console.log('更新排课题库', data)
      console.log('123', this.lesson_id)
      // if (this.lesson_id) {
      //   fPost("/lesson/replaceContest", {
      //     lesson_id: this.lesson_id,
      //     contestIds: [data[1].contest_id]
      //   })
      //     .then(res => {
      //       if (res.state === "success") {
      //         $msg("题库更新成功");
      //         this.openDialog();
      //         // this.getLessionStudentMessage()
      //       } else {
      //         $msg(res.errMsg, 2);
      //       }
      //     })
      //     .catch(err => {
      //       $msg("请求失败", 1);
      //     });
      // } else {
      //   console.log('else')
      //   this.contestIds = data;
      //   let contestIdsArray = [];
      //   this.contestIds.map(item => {
      //     contestIdsArray.push(item.contest_id);
      //   });
      //   this.formData.contestIds = contestIdsArray;
      // }
    },

    // 保存
    saveRoleForm () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          console.log(this.formData.duration)
          if(this.formData.duration === 0) {
            window.$msg('上课时长不能为0',2)
            return false
          }
          if (!this.addlessonData) {
            this.$emit('addLessonForm', this.formData)
          } else {
            this.$emit('editLessonForm', this.formData)
          }
        }
      })
    },

    // 查看老师剩余时间
    checkTime () {
      this.timeDialog = true
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
.duration {
  width: 35px;
  border: 0 !important;
  /deep/ .el-input__inner {
    width: 35px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-radius: 0;
    padding: 0 9px;
    // height: 15px;
    // line-height: 15px;
  }
}
.colon {
  display: inline-block;
  margin: 0 8px;
}
</style>
