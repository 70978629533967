var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.close,
        "close-on-click-modal": false,
        visible: _vm.show,
        width: "70%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("补课申请")])]
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.stuList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "学号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.loginName))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "姓名" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "有效期", width: "430" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetimerange",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        "default-time": ["00:00:00"],
                      },
                      on: {
                        change: function ($event) {
                          return _vm.getQueryDate(scope.row, scope.$index)
                        },
                      },
                      model: {
                        value: _vm.chooseData[scope.$index],
                        callback: function ($$v) {
                          _vm.$set(_vm.chooseData, scope.$index, $$v)
                        },
                        expression: "chooseData[scope.$index]",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "课节选择", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择课节" },
                        model: {
                          value: _vm.courseUnitId[scope.$index],
                          callback: function ($$v) {
                            _vm.$set(_vm.courseUnitId, scope.$index, $$v)
                          },
                          expression: "courseUnitId[scope.$index]",
                        },
                      },
                      _vm._l(scope.row.courseUnitlist, function (itemabv) {
                        return _c("el-option", {
                          key: itemabv.id,
                          attrs: { label: itemabv.title, value: itemabv.id },
                        })
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { plain: "", size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.applyCourse(scope.row, scope.$index)
                          },
                        },
                      },
                      [_vm._v("申请补课")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }