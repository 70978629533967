<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="70%"
    height="90%"
    @open="openDialog"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-if="!isDelete">成员列表</span>
      <span v-else>选择学生</span>
    </div>
    <!-- seleStuData：{{seleStuData}} -->
    <el-row :gutter="60">
      <el-col :span="14">
        <el-row style="margin-bottom:20px" :gutter="20">
          <el-col :span="16">
            <el-input
              placeholder="请输入内容"
              v-model="inputVal"
              class="input-with-select"
              @keyup.enter.native="search"
            >
              <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 100px;">
                <el-option label="用户名" value="1"></el-option>
                <el-option label="姓名" value="2"></el-option>
              </el-select>
              <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
            </el-input>
          </el-col>
          <el-col :span="8">
            <el-select v-model="roleValue" placeholder="请选择" @change="roleChange">
              <el-option
                v-for="item in roleOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-table
          :data="dataList"
          ref="stuRef"
          style="box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.15);"
          @select="handleSelection"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="70" align="center"></el-table-column>
          <el-table-column label="用户名" align="center" prop="loginName"></el-table-column>
          <el-table-column label="姓名" align="center" prop="name"></el-table-column>
        </el-table>
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="pagination.pageSizes"
          :page-size="pagination.pageSize"
          :layout="pagination.layout"
          :total="pagination.total"
        ></el-pagination>
      </el-col>
      <el-col :span="10">
        <el-row style="box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.15);padding-bottom: 30px;">
          <el-row class="seleRow">
            <span>已选择：</span>
            <span class="seleNum" v-text="multipleSelectionAll.length"></span>
          </el-row>
          <ul v-for="(item, index) of multipleSelectionAll" :key="index" class="seleList">
            <li class="sele-item">
              {{item.name}} [{{item.loginName}}]
              <i
                v-if="isDelete"
                class="el-icon-delete-solid ico-del"
                @click="delStu(index, item.userId)"
              ></i>
            </li>
          </ul>
        </el-row>
      </el-col>

      <el-col :span="10" v-if="activeType === 'brushpubsh'" style="margin-top: 20px;">
        <el-row style="box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.15);padding-bottom: 30px;">
          <el-row class="seleRow">
            <span>学员分组：</span>
          </el-row>
          <el-row style="padding: 20px 0 0 20px">
            常规课程：
            <el-select v-model="activityCourseContestId" @change="handleSelectChange" placeholder="请选择分组" filterable
                       clearable>
              <el-option
                v-for="item in groupOptions"
                :key="item.id"
                :label="item.tagName"
                :value="item.id">
              </el-option>
            </el-select>
            <el-row style="margin-top: 10px" v-if="grouptwoOptions.length !== 0">
              信息学奥赛冲刺：
              <el-select v-model="activityCourseContestTwoId" @change="handleSelectTwoChange" placeholder="请选择分组"
                         filterable clearable>
                <el-option
                  v-for="item in grouptwoOptions"
                  :key="item.id"
                  :label="item.tagName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-row>
            <el-row style="margin-top: 10px" v-if="groupthreeOptions.length !== 0">
              人工智能展示活动：
              <el-select v-model="activityCourseContestThreeId" placeholder="请选择分组"
                         filterable clearable>
                <el-option
                  v-for="item in groupthreeOptions"
                  :key="item.id"
                  :label="item.tagName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-row>
          </el-row>
        </el-row>
      </el-col>
    </el-row>
    <el-row slot="footer">
      <el-button type="primary" @click="editSave" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
  import {getUserList} from "@/api/system/staff";
  import {getActivityByGroup} from '@/api/planning/activity'

  export default {
    props: {
      show: {
        default: false,
        type: Boolean
      },
      seleStuData: {
        default: () => {
          return [];
        },
        type: Array
      },
      isDelete: {
        default: true,
        type: Boolean
      },
      activeType: {
        default: '',
        type: String
      }
    },
    watch: {
      seleStuData: {
        handler(val) {
          // 数据同步
          this.multipleSelectionAll = val;
        },
        immediate: true,
        deep: true
      }
    },
    data() {
      return {
        roleOptions: [
          {
            value: "Student",
            label: "学生"
          },
          {
            value: "Teacher",
            label: "员工"
          }
        ],
        roleValue: "Student", //
        roleCodeSet: null,
        dataList: [],
        seleStu: [],
        placeText: "",
        inputVal: "",
        select: "2",
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
          layout: "prev, pager, next, jumper",
          total: 0
        },
        multipleSelectionAll: [], // 所有选中的数据包含跨页数据
        multipleSelection: [], // 当前页选中的数据
        idKey: "userId", // 标识列表数据中每一行的唯一键的名称(需要按自己的数据改一下)
        activityCourseContestId: '',
        activityCourseContestTwoId: '',
        activityCourseContestThreeId: '',
        groupOptions: [],
        grouptwoOptions: [],
        groupthreeOptions: []
      };
    },
    methods: {
      // dialog 组件窗口回调函数
      openDialog() {
        this.roleValue = "Student";
        this.activityCourseContestId = ''
        this.activityCourseContestTwoId = ''
        this.activityCourseContestThreeId = ''
        this.grouptwoOptions = []
        this.groupthreeOptions = []
        this.inputVal = "";
        this.select = "2";
        this.pagination.currentPage = 1;
        this.multipleSelectionAll = Object.assign([], this.seleStuData);
        this.getListStudent();
        this.changePageCoreRecordData();
        this.getActivityByGroup(1, '')
      },
      handleSelectChange() {
        let groupName = ''
        this.groupOptions.forEach(item => {
          if (item.id === this.activityCourseContestId) {
            groupName = item.groupName
          }
        })
        this.getActivityByGroup(2, groupName)
      },

      handleSelectTwoChange() {
        let groupName = ''
        this.grouptwoOptions.forEach(item => {
          if (item.id === this.activityCourseContestTwoId) {
            groupName = item.groupName
          }
        })
        this.getActivityByGroup(3, groupName)
      },
      async getActivityByGroup(group, groupNameThemb) {
        const res = await getActivityByGroup({
          activityId: this.$route.query.activityID,
          level: group,
          groupName: group === 1 ? "" : groupNameThemb
        })
        if (group === 1) {
          this.groupOptions = res.body
        } else if (group === 2) {
          this.grouptwoOptions = res.body
        } else if (group === 3) {
          this.groupthreeOptions = res.body
        }

      },

      // 全部学生列表
      getListStudent() {
        if (this.roleValue === "Student") {
          this.roleCodeSet = [1];
        } else {
          this.roleCodeSet = [1, 2];
        }
        getUserList({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          loginName: this.select === "1" ? this.inputVal : "",
          name: this.select === "2" ? this.inputVal : "",
          state: 1,
          roleCodeSet: this.roleCodeSet
        }).then(res => {
          if (res.state === 'success') {
            this.dataList = res.body.list;
            this.pagination.total = res.body.total;
            setTimeout(() => {
              this.setSelectRow();
            }, 200);
          }
        })
      },

      // 选择角色
      roleChange(val) {
        this.changePageCoreRecordData();
        this.roleValue = val;
        this.getListStudent();
      },

      // 关闭
      close() {
        this.$emit("close");
        if (!this.isDelete) {
          this.$refs.stuRef.clearSelection()
        }
      },

      // 搜索
      search() {
        this.pagination.currentPage = 1;
        this.getListStudent();
      },

      // 分页
      handleSizeChange(val) {
        this.changePageCoreRecordData();
        this.pagination.pageSize = val;
        this.getListStudent();
      },
      handleCurrentChange(val) {
        this.changePageCoreRecordData();
        this.pagination.currentPage = val;
        this.getListStudent();
      },

      // 任意位置勾选
      handletaskTable(val) {
        this.$refs.stuRef.toggleRowSelection(val);
      },

      // 手动勾选Checkbox触发的事件
      handleSelection(val) {
        this.multipleSelection = val;
        this.changePageCoreRecordData();
      },

      // 监听选项发生改变
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },

      // 保存
      editSave() {
        if (this.multipleSelectionAll.length <= 0) {
          window.$msg('请选择要新增的成员', 2)
          return false;
        }
        if (this.activeType === 'brushpubsh' && !this.activityCourseContestId) {
          window.$msg('请选择活动分组', 2)
          return false
        }
        let thembArray = []
        if (this.activityCourseContestId !== '') {
          let thembObject = {}
          thembObject.activityCourseContestId = this.activityCourseContestId
          thembArray.push(thembObject)
        }
        if (this.activityCourseContestTwoId !== '') {
          let thembObject = {}
          thembObject.activityCourseContestId = this.activityCourseContestTwoId
          thembArray.push(thembObject)
        }
        if (this.activityCourseContestThreeId !== '') {
          let thembObject = {}
          thembObject.activityCourseContestId = this.activityCourseContestThreeId
          thembArray.push(thembObject)
        }


        this.$nextTick(() => {
          this.changePageCoreRecordData();
          // if (this.multipleSelectionAll.length <= 0) {
          //   this.$message({ message: "无勾选数据！", type: "warning" });
          //   return;
          // }
          this.$emit("editStuData", this.multipleSelectionAll, this.activityCourseContestId,thembArray);
          this.close()
        });
      },

      // 删除学员
      delStu(index, id) {
        if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
          return;
        }
        this.multipleSelectionAll.splice(index, 1);
        // 标识当前行的唯一键的名称
        let idKey = this.idKey;
        let selectAllIds = id;
        for (var i = 0; i < this.dataList.length; i++) {
          if (selectAllIds.indexOf(this.dataList[i][idKey]) >= 0) {
            // 设置选中，记住table组件需要使用ref="table"
            this.$refs.stuRef.toggleRowSelection(this.dataList[i], false);
          }
        }
      },

      // 记忆选择核心方法
      changePageCoreRecordData() {
        // 标识当前行的唯一键的名称
        let idKey = this.idKey;
        let that = this;
        // 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
        if (this.multipleSelectionAll.length <= 0) {
          this.multipleSelectionAll = this.multipleSelection;
          return;
        }
        // 总选择里面的key集合
        let selectAllIds = [];
        this.multipleSelectionAll.forEach(row => {
          selectAllIds.push(row[idKey]);
        });
        let selectIds = [];
        // 获取当前页选中的id
        this.multipleSelection.forEach(row => {
          selectIds.push(row[idKey]);
          // 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
          if (selectAllIds.indexOf(row[idKey]) < 0) {
            that.multipleSelectionAll.push(row);
          }
        });
        let noSelectIds = [];
        // 得到当前页没有选中的id
        this.dataList.forEach(row => {
          if (selectIds.indexOf(row[idKey]) < 0) {
            noSelectIds.push(row[idKey]);
          }
        });
        noSelectIds.forEach(id => {
          if (selectAllIds.indexOf(id) >= 0) {
            for (let i = 0; i < that.multipleSelectionAll.length; i++) {
              if (that.multipleSelectionAll[i][idKey] == id) {
                // 如果总选择中有未被选中的，那么就删除这条
                that.multipleSelectionAll.splice(i, 1);
                break;
              }
            }
          }
        });
      },

      // 设置选中的方法
      setSelectRow() {
        if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
          return;
        }
        // 标识当前行的唯一键的名称
        let idKey = this.idKey
        let selectAllIds = []
        let that = this
        // this.multipleSelectionAll = Object.assign([], this.seleStuData);
        this.multipleSelectionAll.forEach(row => {
          selectAllIds.push(row[idKey])
        })
        for (var i = 0; i < this.dataList.length; i++) {
          if (selectAllIds.indexOf(this.dataList[i][idKey]) >= 0) {
            // 设置选中，记住table组件需要使用ref="table"
            this.$refs.stuRef.toggleRowSelection(this.dataList[i], true)
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../style/dialog.scss";

  /deep/ .el-table td {
    padding: 10px 0 !important;
  }

  /deep/ .el-dialog {
    max-height: 90%;
    overflow: auto;
  }

  .seleRow {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    background: #efefef;
    box-sizing: border-box;
    padding-left: 25px;
  }

  .seleNum {
    color: #fff;
    border-radius: 50%;
    padding: 1px 8px;
    background: #cccccc;
  }

  .seleList {
    box-sizing: border-box;
    margin-top: 20px;
    padding-left: 20px;
  }

  .ico-del {
    display: inline-block;
    margin-right: 50px;
    cursor: pointer;
  }
</style>
