var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "70%",
        height: "90%",
        top: "7vh",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          !_vm.isDelete
            ? _c("span", [_vm._v("成员列表")])
            : _c("span", [_vm._v("选择学生")]),
        ]
      ),
      _c(
        "el-row",
        { attrs: { gutter: 60 } },
        [
          _c(
            "el-col",
            { attrs: { span: 14 } },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: { placeholder: "请输入内容" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.search.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.inputVal,
                            callback: function ($$v) {
                              _vm.inputVal = $$v
                            },
                            expression: "inputVal",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100px" },
                              attrs: { slot: "prepend", placeholder: "请选择" },
                              slot: "prepend",
                              model: {
                                value: _vm.select,
                                callback: function ($$v) {
                                  _vm.select = $$v
                                },
                                expression: "select",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "用户名", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "姓名", value: "2" },
                              }),
                            ],
                            1
                          ),
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: { click: _vm.search },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.roleChange },
                          model: {
                            value: _vm.roleValue,
                            callback: function ($$v) {
                              _vm.roleValue = $$v
                            },
                            expression: "roleValue",
                          },
                        },
                        _vm._l(_vm.roleOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  ref: "stuRef",
                  staticStyle: {
                    "box-shadow": "0px 2px 9px 0px rgba(0, 0, 0, 0.15)",
                  },
                  attrs: { data: _vm.dataList },
                  on: {
                    select: _vm.handleSelection,
                    "selection-change": _vm.handleSelectionChange,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "70", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "用户名",
                      align: "center",
                      prop: "loginName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "姓名", align: "center", prop: "name" },
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                staticClass: "pagination",
                attrs: {
                  "current-page": _vm.pagination.currentPage,
                  "page-sizes": _vm.pagination.pageSizes,
                  "page-size": _vm.pagination.pageSize,
                  layout: _vm.pagination.layout,
                  total: _vm.pagination.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "box-shadow": "0px 2px 9px 0px rgba(0, 0, 0, 0.15)",
                    "padding-bottom": "30px",
                  },
                },
                [
                  _c("el-row", { staticClass: "seleRow" }, [
                    _c("span", [_vm._v("已选择：")]),
                    _c("span", {
                      staticClass: "seleNum",
                      domProps: {
                        textContent: _vm._s(_vm.multipleSelectionAll.length),
                      },
                    }),
                  ]),
                  _vm._l(_vm.multipleSelectionAll, function (item, index) {
                    return _c("ul", { key: index, staticClass: "seleList" }, [
                      _c("li", { staticClass: "sele-item" }, [
                        _vm._v(
                          " " +
                            _vm._s(item.name) +
                            " [" +
                            _vm._s(item.loginName) +
                            "] "
                        ),
                        _vm.isDelete
                          ? _c("i", {
                              staticClass: "el-icon-delete-solid ico-del",
                              on: {
                                click: function ($event) {
                                  return _vm.delStu(index, item.userId)
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm.activeType === "brushpubsh"
            ? _c(
                "el-col",
                { staticStyle: { "margin-top": "20px" }, attrs: { span: 10 } },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "box-shadow": "0px 2px 9px 0px rgba(0, 0, 0, 0.15)",
                        "padding-bottom": "30px",
                      },
                    },
                    [
                      _c("el-row", { staticClass: "seleRow" }, [
                        _c("span", [_vm._v("学员分组：")]),
                      ]),
                      _c(
                        "el-row",
                        { staticStyle: { padding: "20px 0 0 20px" } },
                        [
                          _vm._v(" 常规课程： "),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择分组",
                                filterable: "",
                                clearable: "",
                              },
                              on: { change: _vm.handleSelectChange },
                              model: {
                                value: _vm.activityCourseContestId,
                                callback: function ($$v) {
                                  _vm.activityCourseContestId = $$v
                                },
                                expression: "activityCourseContestId",
                              },
                            },
                            _vm._l(_vm.groupOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.tagName, value: item.id },
                              })
                            }),
                            1
                          ),
                          _vm.grouptwoOptions.length !== 0
                            ? _c(
                                "el-row",
                                { staticStyle: { "margin-top": "10px" } },
                                [
                                  _vm._v(" 信息学奥赛冲刺： "),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择分组",
                                        filterable: "",
                                        clearable: "",
                                      },
                                      on: { change: _vm.handleSelectTwoChange },
                                      model: {
                                        value: _vm.activityCourseContestTwoId,
                                        callback: function ($$v) {
                                          _vm.activityCourseContestTwoId = $$v
                                        },
                                        expression:
                                          "activityCourseContestTwoId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.grouptwoOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.tagName,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.groupthreeOptions.length !== 0
                            ? _c(
                                "el-row",
                                { staticStyle: { "margin-top": "10px" } },
                                [
                                  _vm._v(" 人工智能展示活动： "),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择分组",
                                        filterable: "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.activityCourseContestThreeId,
                                        callback: function ($$v) {
                                          _vm.activityCourseContestThreeId = $$v
                                        },
                                        expression:
                                          "activityCourseContestThreeId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.groupthreeOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.tagName,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: { click: _vm.editSave },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }