<template>
  <el-dialog
    :before-close="close"
    :close-on-click-modal="false"
    :visible.sync="show"
    @open="openDialog"
    width="70%"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>补课申请</span>
    </div>
    <el-table
      :data="stuList"
      border
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="学号">
        <template slot-scope="scope">
          <span>{{ scope.row.loginName }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="姓名">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="有效期" width="430">
        <template slot-scope="scope">
          <el-date-picker
            v-model="chooseData[scope.$index]"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00']"
            @change="getQueryDate(scope.row,scope.$index)"
          ></el-date-picker>
          <!-- <el-date-picker
            @change="getQueryDate(scope.row,scope.$index)"
            align="right"
            end-placeholder="结束日期"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
            unlink-panels
            v-model="chooseData[scope.$index]">
          </el-date-picker> -->
        </template>
      </el-table-column>
      <el-table-column align="center" label="课节选择" width="300">
        <template slot-scope="scope">
            <el-select v-model="courseUnitId[scope.$index]"  placeholder="请选择课节">
              <el-option
                v-for="itemabv in scope.row.courseUnitlist"
                :key="itemabv.id"
                :label="itemabv.title"
                :value="itemabv.id" >
              </el-option>
            </el-select>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="150">
        <template slot-scope="scope">
          <el-button @click="applyCourse(scope.row,scope.$index)" plain size="small" type="primary">申请补课</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
  import { getLessionStudentMessage, setAttachmentToUser} from '@/api/senate/class'
  export default {
    name: "courseClass",
    props: {
      show: {
        default: false,
        type: Boolean
      },
      applyCourseData: {
        default: () => {
          return {};
        },
        type: Object
      },
    },
    data() {
      return {
        dialogPassword: false,
        lesson_id: 0,
        resetUse: {},
        stuList: [],
        chooseData: [],
        courseUnitId: []
      };
    },

    methods: {
      getQueryDate(row,index){
        this.stuList[index].startTime = this.chooseData[index][0]
        this.stuList[index].endTime = this.chooseData[index][1]
      },

      async getLessionStudentMessage(lesson_id) {
        const res = await getLessionStudentMessage({
          lesson_id: this.lesson_id
        })
        if (res.state === "success") {
          this.stuList = res.body;
          for (let i = 0; i < this.stuList.length; i++) {
            this.chooseData.push("chooseData"+i)
            this.stuList[i].startTime = ""
            this.stuList[i].endTime = ""
            this.stuList[i].courseUnitlist = this.applyCourseData.courseUnitlist
            this.courseUnitId.push("");
          }
        } else {
          $msg(res.data.errMsg, 2);
        }
      },
      async setAttachmentToUser(row,userIds,courseUnitId) {
        const res = await setAttachmentToUser({
          courseUnitId: courseUnitId,
          lessonId: this.lesson_id,
          userIds: userIds,
          startTime:row.startTime,
          endTime:row.endTime
        })
        if(res.state === 'success') {
          $msg('申请成功')
        } else {
          $msg(res.errMsg, 2)
        }
      },

      openDialog() {
        console.log(JSON.stringify(this.applyCourseData.courseUnitlist))
        this.courseUnitId = []
        this.chooseData = []
        this.lesson_id = this.applyCourseData.id
        this.getLessionStudentMessage();
      },
      close() {
        this.$emit("close");
      },
      applyCourse(row,index) {
        if(row.startTime == ""){
          $msg("请选择补课有效期",2)
          return false;
        }
        if(this.courseUnitId[index] === "") {
          $msg("请选择补课课节",2)
          return false;
        }
        var userIds = [row.student_id];
        userIds.push()
        console.log(this.courseUnitId[index])
        this.setAttachmentToUser(row,userIds,this.courseUnitId[index]);
      },

    }
  };
</script>

<style lang="scss" scoped>
@import "~@/style/dialog.scss";
</style>
