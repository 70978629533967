<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="60%"
    top="5vh"
    @open="openDialog"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>编辑课堂反馈</span>
    </div>

    <el-form ref="formData" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="课堂反馈：" prop="feedback">
        <Tinymce v-model="formData.feedback" width="100%" :height="350" v-if="show" />
      </el-form-item>
    </el-form>

    <el-row slot="footer">
      <el-button size="small" @click="save" type="primary">保 存</el-button>
      <el-button size="small" type="info" @click="close">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import Tinymce from '@/components/newTinymce'
import { getFeedback, feedback } from '@/api/senate/lesson'

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    lesson_id: {
      default: '',
      type: String
    }
  },
  components: { Tinymce },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data() {
    let outputTextVal = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入课堂反馈'))
      } else {
        callback()
      }
    }
    return{
      formData: {
        feedback: ''
      },
      rules: {
        feedback: [{ required: true, validator: outputTextVal, trigger: 'blur' }],
      }
    }
  },
  methods: {
    openDialog() {
      this.getFeedback()
    },

    getFeedback() {
      getFeedback({
        lessonId: this.lesson_id
      }).then(res => {
        if (res.state === 'success') {
          if (res.state === 'success') {
            this.formData.feedback = res.body.feedback
            this.$forceUpdate()
          }
        }
      })
    },

    close() {
      this.$emit('close')
    },

    save() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          feedback({
            id: this.lesson_id,
            feedback: this.formData.feedback
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('编辑成功')
              this.close()
              this.$emit('updateView')
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../style/dialog.scss';
</style>
